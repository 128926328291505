import React, { useEffect, useState } from 'react';
import { InterMilesTheme } from '@jetprivilege/web/dist/theme';
import { Button } from '@jetprivilege/web/dist/button';
import { VariantOfAutocomplete } from '@jetprivilege/web/dist/variant-of-autocomplete';
import { DateRangeCalendar } from '@jetprivilege/web/dist/date-range-calendar';
import { PaxSelection } from '@jetprivilege/web/dist/pax-selection';
import { addDays, format, differenceInCalendarDays } from 'date-fns';
import ReactHtmlParser from 'html-react-parser';
import axios from "axios";
import config from '../../config/config_frontend.json';
import { AdobeErrorEvent } from "../../Common_JS/searchWidget/searchWidgetAdobe"
import { setUserInfo } from "../../Common_JS/searchWidget/searchWidgetAdobe";
import { getAutoCompleteImg, preg_quote, HandleSearch } from "../../Common_JS/searchWidget/searchWidget";


const parseDate = require('date-fns').parse;
const isAfter = require('date-fns').isAfter;
const isToday = require('date-fns').isToday;

export default function SearchWidget(props) {
    const [isAPIError, setisAPIError] = useState(false);
    // const [isRecentSearchClicked, setisRecentSearchClicked] = useState(false)
    const [DefaultSelectValue, setDefaultSelectValue] = useState("")
    const [destinationError, setdestinationError] = useState("")
    const [propertyName, setpropertyName] = useState("")
    const [startDate, setstartDate] = useState(addDays(new Date(), 1));
    const [endDate, setendDate] = useState(addDays(new Date(), 2));
    const [isCalendarOpen, setisCalendarOpen] = useState(false);
    const [value1, setvalue1] = useState("")
    const [selectedIcon, setselectedIcon] = useState("");
    // const [SelectedIcon1, setSelectedIcon1] = useState("");
    const [noResultFoundFlag, setnoResultFoundFlag] = useState(false);
    const [suggestedArray, setsuggestedArray] = useState([]);
    const [isAutoCompleteVisible, setisAutoCompleteVisible] = useState(false);
    const [autoCompleteTitle, setautoCompleteTitle] = useState("");
    const [suggestedSearchFlag, setsuggestedSearchFlag] = useState(false);
    const [recentSearchFlag, setrecentSearchFlag] = useState(false);
    const [recentSearchArray, setrecentSearchArray] = useState([]);
    const [destinationId, setdestinationId] = useState("");
    const [propertyCount, setpropertyCount] = useState(0);
    const [propertyType, setpropertyType] = useState("")
    const [search_type, setsearch_type] = useState(0)
    const [dest_type, setdest_type] = useState("");
    const [prev_dest, setprev_dest] = useState("");
    const [property_type_name, setproperty_type_name] = useState("");
    const [dateRangeError, setdateRangeError] = useState("")
    const [sameDateError, setsameDateError] = useState(false);
    const [rooms, setrooms] = useState({
        rooms: [{
            room: 1,
            adult: 2,
            children: 0,
            childAge: [],
            adultIncrement: true,
            adultDecrement: true,
            childIncrement: true,
            childDecrement: true,
            disable: false,
        }],
        adults: 2,
        childrens: 0,
    })
    const [clearButton, setclearButton] = useState()

    useEffect(() => {
        async function fetchData() {
            setUserInfo(props.loggedIn_Info);
            let localValue = JSON.parse(localStorage.getItem('SearchLocation'));
            if (localValue && localValue.length) {
                if (localValue.length > 3) localValue = localValue.slice(0, 3)
            } else {
                localValue = []
            }
            localValue.map(fact => {
                if ((!isToday(parseDate(fact.daterange.split('-')[0].trim(), 'dd MM yyyy', new Date())) && isAfter(new Date(), parseDate(fact.daterange.split('-')[0].trim(), 'dd MM yyyy', new Date()))) || parseDate(fact.daterange.split('-')[0].trim(), 'dd MM yyyy', new Date()).toString() === 'Invalid Date') {
                    fact.daterange = (format(addDays(new Date(), 1), 'dd MM yyyy') + ' - ' + format(addDays(new Date(), 2), 'dd MM yyyy'));
                }
                return fact
            })
            localStorage.setItem('SearchLocation', JSON.stringify(localValue))
            // console.log("localValue", localValue);
            setrecentSearchArray(localValue);
            if (localValue.length > 0) {
                setdestinationId(localValue[0].destination_id);
                setautoCompleteTitle('Recent Searches');
                setrecentSearchFlag(true);
                setvalue1(localValue[0].location);
                setDefaultSelectValue(localValue[0].location);
                setpropertyName(localValue[0].propertyName);
                setpropertyType(localValue[0].type);
                setsearch_type(localValue[0].search_type);
                setdest_type(localValue[0].dest_type);
                setprev_dest(localValue[0].location);
                setproperty_type_name(localValue[0].property_type_name);
                setstartDate(parseDate(localValue[0].daterange.split('-')[0].trim(), 'dd MM yyyy', new Date()))
                setendDate(parseDate(localValue[0].daterange.split('-')[1].trim(), 'dd MM yyyy', new Date()))
                setrooms({
                    adults: localValue[0].PaxArray.adults,
                    rooms: localValue[0].PaxArray.rooms,
                    childrens: localValue[0].PaxArray.childrens
                })
            }
        }
        fetchData();

    }, [props])

    function AutoSuggestResult(e) {
        // setSelectedIcon1("");
        setvalue1(e.target.value ? e.target.value : "");
        setdestinationError("");
        setisCalendarOpen(false);

        if (value1.length !== 0 || (value1.length === 0 && recentSearchArray.length === 0)) {
            let searchResult = {
                "key": e.target.value ? e.target.value : "",
                "type": "desktop"
            }
            axios.post('/hotelsapi/autosuggest', searchResult, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if (!res.data) {
                    setisAPIError(true)
                    return
                } else {
                    if (res != undefined && (res.data.data !== undefined && res.data.data.length !== 0)) {

                        if (value1.length !== 0 || (value1.length === 0 && recentSearchArray.length === 0)) {
                            setrecentSearchFlag(false)
                            let data = res.data.data.length > 0 && res.data.data.map((item, key) => {
                                return {
                                    id: key + 1,
                                    //state is shown in dropdown menu
                                    state: item.full_name,
                                    //label is displayed on search box
                                    label: item.full_name,
                                    destination_id: item.destination_id,
                                    search_type: item.property_type,
                                    property_count: item.property_count,
                                    property_type: item.property_type,
                                    property_type_name: item.property_type_name == "NA" ? "Property" : item.property_type_name,
                                    dest_type: item.destination_type_name == "NA" ? "Area" : item.destination_type_name,
                                    type: item.property_type == 21 ? "hotel" : "city",
                                }
                            })
                            setsuggestedArray(data)
                            setnoResultFoundFlag(data.length == undefined ? true : false)
                            setautoCompleteTitle((recentSearchArray.length == 0 && value1.length < 2) ? 'Popular Destinations' : 'Suggestions')
                            setsuggestedSearchFlag(true)
                        } else {
                            setsuggestedSearchFlag(false);
                            setsuggestedArray([]);
                            setrecentSearchFlag((recentSearchArray.length > 0) ? true : false)
                            setautoCompleteTitle((recentSearchArray.length > 0) ? 'Recent Searches' : '');
                            setnoResultFoundFlag(false)
                        }
                    } else if (!res.data.status) {
                        setnoResultFoundFlag(true);
                        setsuggestedArray(res.data.data)
                        setsuggestedSearchFlag(false);
                        setrecentSearchFlag(false);
                        setautoCompleteTitle("");
                    } else {
                        setsuggestedSearchFlag(false);
                        setsuggestedArray([]);
                        setrecentSearchFlag(true);
                        setautoCompleteTitle('Recent Searches')
                        setnoResultFoundFlag(false)
                    }
                }
            }).catch((err) => {
                console.log("Autosuggest Error------>", err)
                AdobeErrorEvent()
            }
            )

        } else if (value1.length < 2) {
            setsuggestedSearchFlag(false)
            setsuggestedArray([]);
            setrecentSearchFlag((recentSearchArray.length > 0) ? true : false)
            setautoCompleteTitle((recentSearchArray.length > 0) ? 'Recent Searches' : '')
            setnoResultFoundFlag(false);
        }

        
    }

    function onSelect(value, item) {
        setselectedIcon(`https://www.countryflags.io/${item.icon}/flat/64.png`);
        setvalue1(value);
        setprev_dest(value);
        setDefaultSelectValue(value);
        setisAutoCompleteVisible(false);
        setdestinationId(item.destination_id);
        setpropertyCount(item.property_count);
        setpropertyType(item.type);
        setsearch_type(item.search_type);
        setdest_type(item.dest_type);
        setproperty_type_name(item.property_type_name);
        setpropertyName(value.split(',')[0]);
        setdestinationError("");
        setisCalendarOpen(true)
        setclearButton(true)

        let Checkin = item.daterange !== undefined ?
            parseDate(item.daterange.split('-')[0].trim(), 'dd MM yyyy', new Date())
            : startDate !== undefined && differenceInCalendarDays(startDate, new Date()) >= 0
                ? startDate : addDays(new Date(), 1)

        setstartDate(Checkin)

        let Checkout = item.daterange !== undefined ?
            parseDate(item.daterange.split('-')[1].trim(), 'dd MM yyyy', new Date())
            : endDate !== undefined && differenceInCalendarDays(endDate, new Date()) > 0
                ? endDate : addDays(new Date(), 2);

        setendDate(Checkout)
        if (item.PaxArray) {
            setrooms({
                adults: item.PaxArray.adults,
                rooms: item.PaxArray.rooms,
                childrens: item.PaxArray.childrens
            })
        }

    }

    function handleBlur(event, type) {
        // console.log("prev data",this.state.prev_dest);
        setvalue1(prev_dest);
        setsameDateError(false);
        setdateRangeError("");
    }


    function handleFocus(e) {
        //  debugger;
        AutoSuggestResult(e);
        setisAutoCompleteVisible(true);
        setdestinationError("")
        setdateRangeError("")

    }

    function clearField() {
        setclearButton()
        setvalue1("")
        setautoCompleteTitle("");
    }

    const RenderMenu = (children) => {

        return (
            <div className={`intermiles-homepage-search ${suggestedArray.length === 0 ? 'recent' : 'suggest'}`}>
                {
                    (suggestedArray.length == undefined || noResultFoundFlag) ?
                        <div className='jp-no-result-found'>
                            <img className="jp-no-result-found-img" src={'/nextHotels/img/no-result-found.png'} alt="" />
                            <div className='jp-no-result-found-text'><b>Oops! No Result Found!</b></div>
                        </div>
                        :
                        <div className='intermiles-homepage-search-inner'>
                            <div className='intermiles-title'>{autoCompleteTitle}</div>
                            <div id="ex1-label" />
                            <ul
                                role="listbox"
                                id="ex1-listbox"
                                aria-labelledby="ex1-label"
                                className={'ul-style border-desktop-ul'}
                            >
                                <div className="jp-mobile-recently-searched">{children}</div>
                            </ul>
                        </div>
                }
            </div>
        )
    }

    const onDateSelectionChange = (result) => {
        if ((result.valid === false) && (result.errorCode === 1)) {
            setdateRangeError("Invalid checkin and checkout date");
            setsameDateError(true)
        } else {
            setdateRangeError("");
            setsameDateError(false);
        }
        setstartDate(result.startDate)
        setendDate(result.endDate)
    }

    function onChangeCloseButton() {
        setvalue1("");
        // setSelectedIcon1("")
        let elementInput = document.getElementById('search-flyout-mobile-input');
        if (elementInput !== null) elementInput.focus();
    }

    async function handleSearch() {
        let searchData = await HandleSearch(startDate, endDate, value1, propertyType, destinationId, rooms, autoCompleteTitle, sameDateError, DefaultSelectValue, propertyName, propertyCount, search_type, dest_type, property_type_name, props.mop);
        // console.log("handleSearch ~ searchData", searchData)
        if (searchData.UrlString) {
            setTimeout(() => { window.location.href = config.Localhost + searchData.UrlString; }, 1000)
        } else {
            setdestinationError(searchData.destinationError);
            setvalue1(searchData.value1);
            setdateRangeError(searchData.dateRangeError);
        }
    }

    function RenderItem(item, highlighted) {
        let location = String(item.state).replace(new RegExp("(" + preg_quote(value1) + ")", 'i'), "<b>$1</b>");
        // let location=item.state
        //console.log("location", location);
        let PassedRecentVal = ''
        //console.log("recentSearchArray", recentSearchArray.length, recentSearchFlag);
        if (recentSearchArray.length > 0 && recentSearchFlag) {
            let var1 = item?.PaxArray?.adults > 1 ? ' Adults ' : ' Adult ';
            let var2 = item?.PaxArray?.childrens > 1 ? ' Children ' : item?.PaxArray?.childrens > 0 ? ' Child ' : '';
            let var3 = item?.PaxArray?.childrens !== 0 ? item?.PaxArray?.childrens : '';
            PassedRecentVal = item?.PaxArray?.adults + var1 + var3 + var2;
        }
        let autocompleteImg = getAutoCompleteImg(item.dest_type)
        // console.log(recentSearchArray.length, recentSearchFlag);
        let htmlString = (recentSearchArray.length > 0 && recentSearchFlag) ? getrecentSearchDiv(item, autocompleteImg, PassedRecentVal) : getSearchResultDiv(item, autocompleteImg, location);
        // console.log("htmlString", htmlString);
        return (
            <div className='im-autosuggested' style={{ backgroundColor: 'rgba(248, 244, 244)' }}>
                <div
                    key={item?.id}
                    className={'desktop-li-recent-desktop'}
                    style={{ backgroundColor: highlighted ? 'rgba(248, 244, 244, 0.68)' : 'transparent', }}
                >
                    <li
                        id={highlighted ? 'selectedoption' : ''}
                        role="option"
                        data-activedescendant={highlighted}
                        aria-selected={highlighted}
                        style={{ padding: '' }}
                    >
                        {htmlString}
                    </li>
                </div>
            </div>
        );

    }

    function getrecentSearchDiv(item, autocompleteImg, PassedRecentVal) {

        return (
            <div className="jp-country" aria-atomic="true">
                <img className='autocomplete-icon' src={autocompleteImg} />
                <span className="property-title">{item?.location}</span>
                {<b>|</b>}
                {item?.daterange}
                <span className="without-icon-li-state">{format(item?.daterange ? parseDate(item?.daterange?.split('-')[0]?.trim(), 'dd MM yyyy', new Date()) : new Date(), 'do MMM yyyy') + ' - ' + format(item?.daterange ? parseDate(item?.daterange?.split('-')[1]?.trim(), 'dd MM yyyy', new Date()) : new Date(), 'do MMM yyyy')}</span>
                <div className="recent-row-second">
                    {/* <b>{item.PaxArray.rooms.length} Room : </b> */}
                    <b>{(item?.PaxArray?.rooms?.length > 0) && '' + (item?.PaxArray?.rooms.length > 1 ? item?.PaxArray?.rooms?.length + " Rooms : " : "1 Room : ")}</b>
                    <span>{PassedRecentVal}</span>
                </div>
            </div>

        )
    }

    function getSearchResultDiv(item, autocompleteImg, location) {
        // console.log("getSearchResultDiv ~ item", location)
        return (
            <div className="jp-country" aria-atomic="true">
                <div className="countryproperty">
                    <div className="countryimgtitle">
                        <img className='autocomplete-icon' src={autocompleteImg} />
                        <span className="property-title1"> {ReactHtmlParser(location)}</span>
                    </div>
                    <div className="countryimg-state">
                        <span className="without-icon-li-state">
                            {item?.type !== "hotel" ? item?.dest_type : item?.property_type_name}
                            {item?.type !== "hotel" ? ` | ${item?.property_count} Properties` : null}
                        </span>
                    </div>
                </div>
            </div>
        )
    }



    return (
        <div >
            {!isAPIError &&
                <div className="searchwidgetheading jp-searchcontainer landingsearchwidget" id="bagroundId">
                    <div className={isAutoCompleteVisible ? 'jp-block jp-blue' : (!isAutoCompleteVisible && (value1 !== '')) ? 'jp-block jp-none1' : 'jp-block jp-big'}>

                        <div className={`${InterMilesTheme} variant-of-autcomplete variant-without-icon-compenent search-widget`} >

                            <VariantOfAutocomplete
                                className="atou-testing"
                                additionalClassName="interMiles-autocomplete"
                                autoHighlight={false}
                                items={
                                    (suggestedArray.length > 0 && suggestedSearchFlag) ? suggestedArray : recentSearchArray
                                }
                                onBlur={(e) => { handleBlur(e); }}
                                getItemValue={(item) => { return item.label }}
                                renderItem={RenderItem}
                                value={value1}
                                shouldItemRender={(item) => { return item.label }}
                                onChange={(e) => { AutoSuggestResult(e) }}
                                onFocus={(e) => { handleFocus(e); }}
                                onSelect={(value, item) => { onSelect(value, item) }}
                                selectOnBlur={false}
                                searchLabel={""}
                                inputProps={{
                                    placeholder: 'Where would you like to stay?',
                                    id: 'search-flyout-mobile-input',
                                    className: `${(value1 === '') ? 'jp-input-empty' : 'jp-input-value'} jp-home-desktop-input`,
                                    autoFocus: false
                                }}
                                renderMenu={(children) => { return RenderMenu(children) }}
                                // disabledCloseButton={value1.length <= 0}
                                onClickCloseButton={onChangeCloseButton}
                                inputIcon={selectedIcon}
                            />
                            {clearButton ? <span onClick={() => { clearField() }} className="location_clear"></span> : <></>}
                        </div>
                        <div className={((value1 !== '') && !isAutoCompleteVisible) ? '' : 'jp-none'}>

                            <DateRangeCalendar
                                startDate={startDate}
                                endDate={endDate}
                                noofMonthView={2}
                                onSelect={(e) => handleBlur(e)}
                                openCalendar={isCalendarOpen}
                                additionalClassName={['date-range-positionRelative']}
                                onDateSelectionChange={onDateSelectionChange}
                            />
                        </div>
                        {((dateRangeError !== '') && (sameDateError)) &&
                            <div className="error-cstm-msg jp-text-center" id="daterangeError">
                                <p className="jp-color-theme-red">{dateRangeError}</p>
                            </div>
                        }
                        <div className={((value1 !== '') && !isAutoCompleteVisible) ? '' : 'jp-none'}>

                            <PaxSelection
                                accessibleId="pax_selection_1"
                                isMobile={false}
                                adultAgeRangeLabel="(12 yrs & above)"
                                childrenAgeRangeLabel="(2 - 11 yrs)"
                                defaultAdult={2}
                                paxData={rooms}
                                minimumChildAge={2}
                                maximumChildAge={11}
                                minimumPax={1}
                                onChildAgeChange={(e) => setrooms(e)}
                                paxChangeCallBack={(e) => setrooms(e)}
                            />
                        </div>

                    </div>
                    <div className={`${InterMilesTheme} parent`} >

                        <Button buttonType="primary" onClick={() => { handleSearch() }}> Search </Button>

                    </div>
                    {(destinationError !== '') &&
                        <div className="error-cstm-msg jp-text-center" id="destinationError">
                            <p className="jp-color-theme-red">{destinationError}</p>
                        </div>
                    }
                </div>
            }
        </div>
    )
}
